<template>
  <div class="market-place-header">
    <url-path v-if="urls" :urls="urls" class="market-place-header__urls" />
    <div v-else>
        <slot name="actions"/>
    </div>

    <div v-if="false" class="market-place-header__actions">
      <btn
        v-for="(tab, i) in tabs"
        :key="i"
        class="market-place-header__actions-item"
        :type="$route.fullPath.startsWith(tab.path) ? 'play-dark-bg-white' : 'play-transparent-bg-white'"
        :text="tab.label"
        @click="goToUrl(tab.path)"
      />
    </div>
  </div>
</template>

<script>
import UrlPath from '@/components/common/url-path.vue';

export default {
    name: 'MarketPlaceHeader',
    components: {
        UrlPath
    },
    props: {
        urls: { type: Array },
    },
    data() {
        return {
            tabs: [
                {
                    path: '/marketplace/my_nfts',
                    label: 'My nfts',
                },
                {
                    path: '/marketplace/shop',
                    label: 'Shop',
                },
            ],
        }
    },
}
</script>

<style lang="scss">
.market-place-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  color: white;
}

.market-place-header__actions {
  display: flex;
  grid-gap: 10px;
}

.market-place-header__actions-item {
  min-width: 210px;
  margin: 0;
}

@media screen and (max-width: $bp_mb) {
  .market-place-header {
    flex-direction: column;
  }
  .market-place-header__urls {
    margin-bottom: 30px;
  }
  .market-place-header__actions {
      gap: 0;
      width: 100%;

      &-item {
          min-width: 130px;
          border: 0;
          height: 32px;
          width: 50%;

          &.button--play-transparent-bg-white {
              border: 2px solid white;
          }
      }
  }
}

</style>
