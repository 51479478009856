<template>
  <div class="url-path">
    <div
      v-for="(url, i) in urls"
      :key="i"
      class="url-path__item"
      @click="onUrlClick(url)"
    >{{ url.label }}</div>
  </div>
</template>

<script>
export default {
  name: 'UrlPath',
  props: {
    urls: { type: Array, default: () => [] },
  },
  methods: {
    onUrlClick(url) {
      if (url.name) {
        this.$router.push({ name: url.name })
      }
    },
  },
}
</script>

<style lang="scss">

.url-path {
  display: flex;
  margin: auto 0;

    @media screen and (max-width: $bp_mb) {
        :first-child {
            &:before {
                content: "‹";
                position: absolute;
                left: 0;
                top: -8px;
                font-size: 24px;
            }
        }
    }
}

.url-path__item {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;

  cursor: pointer;

  &:after {
    content: "\00a0/\00a0";
  }

  &:last-child {
    color: #ff9800;
    cursor: default;

    &:after {
      content: none;
    }
  }
}

</style>
