var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "market-place-header" },
    [
      _vm.urls
        ? _c("url-path", {
            staticClass: "market-place-header__urls",
            attrs: { urls: _vm.urls },
          })
        : _c("div", [_vm._t("actions")], 2),
      false
        ? _c(
            "div",
            { staticClass: "market-place-header__actions" },
            _vm._l(_vm.tabs, function (tab, i) {
              return _c("btn", {
                key: i,
                staticClass: "market-place-header__actions-item",
                attrs: {
                  type: _vm.$route.fullPath.startsWith(tab.path)
                    ? "play-dark-bg-white"
                    : "play-transparent-bg-white",
                  text: tab.label,
                },
                on: {
                  click: function ($event) {
                    return _vm.goToUrl(tab.path)
                  },
                },
              })
            }),
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }