// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@charset \"UTF-8\";\n.url-path {\n  display: flex;\n  margin: auto 0;\n}\n@media screen and (max-width: 640px) {\n.url-path :first-child:before {\n    content: \"‹\";\n    position: absolute;\n    left: 0;\n    top: -8px;\n    font-size: 24px;\n}\n}\n.url-path__item {\n  font-size: 14px;\n  font-weight: 500;\n  text-transform: uppercase;\n  cursor: pointer;\n}\n.url-path__item:after {\n  content: \" / \";\n}\n.url-path__item:last-child {\n  color: #ff9800;\n  cursor: default;\n}\n.url-path__item:last-child:after {\n  content: none;\n}", ""]);
// Exports
exports.locals = {
	"bp_mb_xs": "430px",
	"bp-mb": "640px",
	"bp-laptop": "1630px",
	"bp-desktop": "992px"
};
module.exports = exports;
