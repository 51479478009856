// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".market-place-header {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  position: relative;\n  color: white;\n}\n.market-place-header__actions {\n  display: flex;\n  grid-gap: 10px;\n}\n.market-place-header__actions-item {\n  min-width: 210px;\n  margin: 0;\n}\n@media screen and (max-width: 640px) {\n.market-place-header {\n    flex-direction: column;\n}\n.market-place-header__urls {\n    margin-bottom: 30px;\n}\n.market-place-header__actions {\n    gap: 0;\n    width: 100%;\n}\n.market-place-header__actions-item {\n    min-width: 130px;\n    border: 0;\n    height: 32px;\n    width: 50%;\n}\n.market-place-header__actions-item.button--play-transparent-bg-white {\n    border: 2px solid white;\n}\n}", ""]);
// Exports
exports.locals = {
	"bp_mb_xs": "430px",
	"bp-mb": "640px",
	"bp-laptop": "1630px",
	"bp-desktop": "992px"
};
module.exports = exports;
