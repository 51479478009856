var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "url-path" },
    _vm._l(_vm.urls, function (url, i) {
      return _c(
        "div",
        {
          key: i,
          staticClass: "url-path__item",
          on: {
            click: function ($event) {
              return _vm.onUrlClick(url)
            },
          },
        },
        [_vm._v(_vm._s(url.label))]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }